.presentation {
  margin: 4rem 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 830px) {
      justify-content: center;
      flex-direction: column-reverse;
      gap: 1rem;
    }

    .descriptionPresentation {
      width: 49%;

      @media (max-width: 830px) {
        width: 100%;
      }

      h1 {
        padding: 1px;
        font-size: 1.9rem;
        font-style: oblique;
        line-height: 30p;
        color:#e68e9a;
      }

      p {
        font-style: oblique;
        font-size: 18px;
        font-weight: 300;
        color: var(--black);
        text-align: center;
        margin: 0.8rem 0;
      }
    }

    .imgPresentation {
      width: 49%;
      margin-top: -155px;
      margin-bottom: -130px;
      height: 510px;
      background: url("../../assets/colagem.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @media (max-width: 830px) {
        width: 100%;
      }
    }
  }
}


.propose {
  margin: 3rem 0;
  margin-top: 10%;
 @media (max-width: 830px) {
     margin-top: 70%;
    }
    a{
      display: flex;
      justify-content: center;
  align-items: center;
      
    }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
   

    @media (max-width: 830px) {
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
    }

    .descriptionPropose {
      width: 49%;

      @media (max-width: 830px) {
        width: 100%;
      }

      h1 {
        font-size: 4rem;
        font-weight: 800;
        line-height: 70px;
        color:#e68e9a;
      }

      p {
        font-style: oblique;
        font-size: 18px;
        text-align: center;
        font-weight: 300;
        color: var(--black);
        margin: 0.8rem 0;
      }
    
    }

    

    .imgPropose {
      width: 49%;
      height: 510px;
      background: url("../../assets/DSC_0930-removebg-preview.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-top: 10%;
      @media (max-width: 830px) {
        width: 100%;
        margin-top: 10%;
      }
    }
    .imgPropose2 {
      width: 49%;
      height: 510px;
      background: url("../../assets/americans.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-top: -55px;
      margin-bottom: -130px;
      @media (max-width: 830px) {
        width: 100%;
        margin-top: -155px;
      }
    }
  }
}

.title {
  text-align: center;

  h1 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 70px;

    @media (max-width: 600px) {
      line-height: 40px;
      margin-bottom: 1rem;
    }
  }

  span {
    font-size: 1rem;
    font-weight: 300;
  }
}

.nogs {
  margin: 3rem 0;

  .nogsContent {
    margin: 1rem 0;
    
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
    }

    .logoNogs {
      width: calc(100% / 4 - 1rem);
      height: 100px;
      background: url("https://yt3.ggpht.com/ytc/AMLnZu9iYZBVbYAiDov-oB9v_ZpUURnf1fHO19pr5C0O=s900-c-k-c0x00ffffff-no-rj");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media (max-width: 600px) {
        width: 200px;
      }
    }
  }
}
.containerqea {
  color: #e68e9a;
}


.whatWeDo {
  margin: 3rem 0;
  margin-top: 10%;
  margin-bottom: 5%;
  @media (max-width: 1000px) {
    margin-top: 20%;
    margin-bottom: 50%;
  }

  .container {
    margin: 2rem auto;
    position: relative;

    .nftImg {
      width: 50%;
      margin: 2rem auto;
      padding: 6rem;

      @media (max-width: 1000px) {
        display: none;
      }

      img {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }
    .boxsInformation {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      

      @media (max-width: 1000px) {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .boxInformation {
        width: 300px;
        position: absolute;
       
        @media (max-width: 1000px) {
          position: relative;
          width: 49%;
          margin: 3rem 0;
        }

        @media (max-width: 370px) {
          width: 100%;
        }

        .boxIcon {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          border: 1px solid var(--main-color);
          display: flex;
          margin-left: auto;
          margin-right: auto;
          justify-content: center;
          margin-bottom: 1rem;

          svg {
            font-size: 1.5rem;
            color: var(--main-color);
           
          }
        }

        h1 {
          font-size: 1.5rem;
          font-weight: 800;
          text-align: center;
        }

        p {
          font-style: oblique;
          font-size: 16px;
          font-weight: 300;
          color: var(--black);
          text-align: center;
        }

        &.one {
          top: 0;
          left: 10%;
          border-radius: 15px;
          border: 2px solid  #e68e9a;
          padding: 10px;
          @media (max-width: 1000px) {
              top: 0;
              left: 0;
              right: 0;
              height: 270px;
              padding: 4px;
          }
      }

      &.two {
          top: 0;
          right: 9%;
          border-radius: 15px;
          border: 2px solid  #e68e9a;
          padding: 15px;
          @media (max-width: 1000px) {
              top: 0;
              left: 0;
              right: 0; 
              height: 270px;
              padding: 6px;
             
          }
      }

      &.three {
          top: 55%;
          left: 0;
          border-radius: 15px;
          border: 2px solid  #e68e9a;
          padding: 10px;
          @media (max-width: 1000px) {
              top: 0;
              left: 0;
              right: 0;
              height: 290px;
              padding: 3px;
          }
      }

      &.four {
          top: 40%;
          right: 0;
          border-radius: 15px;
          border: 2px solid  #e68e9a;
          padding: 10px;
          @media (max-width: 1000px) {
              top: 0;
              left: 0;
              right: 0;
              height: 290px;
              padding: 1px;
          }
      }
      }
    }
  }
}

