button.mainButton {
    width: unquote("min(90vw, 333px)");
    padding: 1.4rem 2rem;
    border-radius: 0.5rem;
    background: #c09776;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 830px) {
    margin-top: 5%;
 
      }
    
    span {
        font-size: 1rem;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        color: var(--white) !important;
    }
}