footer {
    padding: 1rem 0 2rem 0;
    background: var(--main-color);

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 500px) {
            justify-content: center;
            flex-direction: column;
            gap: 1.5rem;
        }

        .logo {
            width: 200px;
            height: 80px;
            background: var(--gray);
        }

        span {
            font-size: 1rem;
            font-weight: 500;
            font-family: "Poppins", sans-serif;
            color: var(--white);

            @media (max-width: 830px) {
                display: none;
            }
        }

        .socials {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            .boxSocials {
                width: 60px;
                height: 60px;
                border-radius: 30px;
                border: 1px solid var(--white);
                color: var(--white);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: filter 200ms;
                cursor: pointer;

                img {
                    width: 30px;
                }

                svg {
                    font-size: 30px;
                }

                &:hover {
                    filter: brightness(0.8);
                }
            }
        }

        nav {
            width: 100%;
            margin: 1rem 0;

            @media (max-width: 830px) {
                display: none;
            }

            ul {
                list-style-type: none;
                display: flex;
                align-items: center;
                justify-content: space-around;

                li {
                    color: var(--white);
                    font-size: 1rem;
                    font-weight: 300;
                    transition: filter 200ms;

                    a {
                        color: inherit;
                        text-decoration: none;
                    }

                    &:hover {
                        filter: brightness(0.8);

                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .line {
            width: 100%;
            height: 1px;
            background: var(--white);
        }
    }

    h3 {
        font-size: 1rem;
        font-weight: 600;
        color: var(--white);
        display: block;
        margin: 0 auto;
        text-align: center;
    }
}

